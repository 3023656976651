import Logo from "@components/Logo"
import { AppBar, Button, Toolbar, useMediaQuery } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import * as routes from "@routes"
import { CustomTheme } from "@theme"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React, { useState } from "react"
import HeaderNavLink from "./HeaderNavLink"
import MobileMenu from "./MobileMenu"
import GoToAppButton from "./GoToAppButton"
import { messages } from "./Header.messages"
import { useIntl } from "gatsby-plugin-intl"

export interface IHeaderProps {
  siteTitle?: string
}

const useCss = makeStyles((theme: CustomTheme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: theme.custom.mobileHeaderHeight,
    boxShadow: "none",
    backgroundColor: "transparent",
    color: "white",
    [theme.breakpoints.up("md")]: {
      height: theme.custom.desktopHeaderHeight,
    },
  },
  logoContainer: {
    flex: "1 1 15%",
  },
  logo: {
    // width: 180,
    width: 160,
    [theme.breakpoints.up("sm")]: {
      width: 180,
    },
    [theme.breakpoints.up("lg")]: {
      width: 208,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    whiteSpace: "nowrap",
    flex: "0 1 15%",
    [theme.breakpoints.up("md")]: {
      flex: "0 0 15%",
    },
    [theme.breakpoints.up("xl")]: {
      flex: "1 1 15%",
    },
  },
  nav: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  desktopButton: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  mobileButton: {
    display: "flex",
  },
  logoLink: {
    textDecoration: "none",
  },
  logoBeta: {
    position: "relative",
    top: "-15px",
    left: "20px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "0 4px 2px",
    borderRadius: "2px",
  },
}))

export default function Header(props: IHeaderProps) {
  const classes = useCss(props)
  const isMobile = useMediaQuery((theme: CustomTheme) =>
    theme.breakpoints.down("sm")
  )
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false)

  return (
    <>
      <MobileMenu open={open} onClose={() => setOpen(false)} />
      <AppBar position="absolute" className={classes.header}>
        <Toolbar>
          <div className={classes.logoContainer}>
            <Link to="/" className={classes.logoLink}>
              <Logo className={classes.logo} />
              <span className={classes.logoBeta}>Beta</span>
            </Link>
          </div>
          <nav className={classes.nav}>
            <HeaderNavLink to={routes.howItWorks}>
              {formatMessage(messages.HowitWorks)}
            </HeaderNavLink>
            <HeaderNavLink to={routes.features}>
              {formatMessage(messages.Features)}
            </HeaderNavLink>
            <HeaderNavLink to={routes.enterprise}>
              {formatMessage(messages.Enterprise)}
            </HeaderNavLink>
            <HeaderNavLink to={routes.team}>
              {formatMessage(messages.Team)}
            </HeaderNavLink>
            <HeaderNavLink to={routes.contact}>
              {formatMessage(messages.Contact)}
            </HeaderNavLink>
          </nav>
          <div className={classes.buttonContainer}>
            <GoToAppButton
              className={classes.desktopButton}
              color="primary"
              variant="contained"
            >
              <FormattedMessage
                defaultMessage="Create Totenpass"
                id="components.Header.CreateTotenpass"
              />
            </GoToAppButton>
            {isMobile && (
              <Button
                className={classes.mobileButton}
                color="primary"
                onClick={() => setOpen(true)}
                variant="contained"
              >
                <FormattedMessage
                  defaultMessage="Menu"
                  id="components.Header.Menu"
                />
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
