import { useAppURL } from "@hooks/use-app-url"
import { usemount } from "@hooks/use-mount"
import Button, { ButtonProps } from "@material-ui/core/Button"
import makeStyles from "@material-ui/core/styles/makeStyles"
import clsx from "clsx"
import React, { useEffect, useRef } from "react"

export interface IGoToAppButtonProps extends ButtonProps {}

// const useCss = makeStyles({
//   GoToAppButton: {
//     position: "relative",
//   },
//   GoToAppButtonLink: {
//     display: "block",
//     color: "inherit",
//     lineHeight: "inherit",
//     height: "100%",
//     left: 0,
//     position: "absolute",
//     textDecoration: "none",
//     top: 0,
//     width: "100%",
//   },
// })

export default function GoToAppButton(props: IGoToAppButtonProps) {
  return null
  // const classes = useCss()
  // const { children, className, ...otherprops } = props
  // const appurl = useAppURL()
  // const aref = useRef<HTMLAnchorElement | null>(null)

  // useEffect(() => {
  //   if (aref && aref.current) {
  //     aref.current.href = appurl
  //   }
  // }, [usemount()])

  // return (
  //   <Button
  //     className={clsx(classes.GoToAppButton, className)}
  //     color="primary"
  //     variant="contained"
  //     {...otherprops}
  //   >
  //     {children}
  //     <a className={classes.GoToAppButtonLink} href={appurl} ref={aref} />
  //   </Button>
  // )
}
