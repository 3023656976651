import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import { changeLocale, useIntl } from "gatsby-plugin-intl"
import React from "react"
import { languages, languagesObject } from "@totenpass/language-configuration"

const useCss = makeStyles((theme: CustomTheme) => ({
  LanguageSelectorDropdown: {
    ...theme.typography.body2,
    color: theme.palette.primary.main,
  },
}))

export default function LanguageSelectorDropdown() {
  const classes = useCss()
  const intl = useIntl()

  if (languages.length < 2) {
    return null
  }

  return (
    <Select
      className={classes.LanguageSelectorDropdown}
      disableUnderline
      onChange={(event) => changeLocale(String(event.target.value))}
      value={intl.locale}
    >
      {languages.map((locale, index) => (
        <MenuItem key={index} value={locale}>
          {(languagesObject as any)[locale]}
        </MenuItem>
      ))}
    </Select>
  )
}
