import { createElement } from "react"
import { Link } from "gatsby-plugin-intl"
import { GatsbyLinkProps } from "gatsby"
import makeStyles from "@material-ui/core/styles/makeStyles"
import clsx from "clsx"

export interface IFooterLinkProps<TState> extends GatsbyLinkProps<TState> {}

const useCss = makeStyles((theme) => ({
  NavFooterLinkLink: {
    ...theme.typography.body2,
    color: "white",
    opacity: 0.5,
    textDecoration: "none",
    transition: "opacity 200ms",
    "&:hover": {
      opacity: 1,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "12px",
      marginRight: "12px",
    },
  },
}))

export default function FooterLink<TState>(props: IFooterLinkProps<TState>) {
  const classes = useCss()
  const { className: otherClassName, ...otherprops } = props

  const className = clsx(classes.NavFooterLinkLink, otherClassName)

  return createElement(Link, {
    className,
    ...otherprops,
  })
}
