import React, { ReactNode } from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

export interface IMobileMenuItemProps {
  children: ReactNode
  onClick: () => any
  to: string
}

export default function MobileMenuItem(props: IMobileMenuItemProps) {
  return (
    <ListItem button component="a" href={props.to} onClick={props.onClick}>
      <ListItemText primary={props.children} />
    </ListItem>
  )
}
