// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-care-instructions-tsx": () => import("./../../../src/pages/care-instructions.tsx" /* webpackChunkName: "component---src-pages-care-instructions-tsx" */),
  "component---src-pages-enterprise-tsx": () => import("./../../../src/pages/enterprise.tsx" /* webpackChunkName: "component---src-pages-enterprise-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leave-review-tsx": () => import("./../../../src/pages/leave-review.tsx" /* webpackChunkName: "component---src-pages-leave-review-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-team-bio-alessandro-premoli-tsx": () => import("./../../../src/pages/team/bio/alessandro-premoli.tsx" /* webpackChunkName: "component---src-pages-team-bio-alessandro-premoli-tsx" */),
  "component---src-pages-team-bio-bruce-ha-tsx": () => import("./../../../src/pages/team/bio/bruce-ha.tsx" /* webpackChunkName: "component---src-pages-team-bio-bruce-ha-tsx" */),
  "component---src-pages-team-bio-carmelo-chiarenza-tsx": () => import("./../../../src/pages/team/bio/carmelo-chiarenza.tsx" /* webpackChunkName: "component---src-pages-team-bio-carmelo-chiarenza-tsx" */),
  "component---src-pages-team-bio-mike-busby-tsx": () => import("./../../../src/pages/team/bio/mike-busby.tsx" /* webpackChunkName: "component---src-pages-team-bio-mike-busby-tsx" */),
  "component---src-pages-team-bio-paolo-roth-tsx": () => import("./../../../src/pages/team/bio/paolo-roth.tsx" /* webpackChunkName: "component---src-pages-team-bio-paolo-roth-tsx" */),
  "component---src-pages-team-bio-roy-sebag-tsx": () => import("./../../../src/pages/team/bio/roy-sebag.tsx" /* webpackChunkName: "component---src-pages-team-bio-roy-sebag-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-thank-you-for-review-tsx": () => import("./../../../src/pages/thank-you-for-review.tsx" /* webpackChunkName: "component---src-pages-thank-you-for-review-tsx" */)
}

