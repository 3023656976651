import List from "@material-ui/core/List"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import * as routes from "@routes"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import { ensureFn } from "../util/functions"
import MobileMenuItem from "./MobileMenuItem"
import { messages } from "./Header.messages"
import { useIntl } from "gatsby-plugin-intl"

export interface IMobileMenuProps {
  open: boolean
  onClose?: () => void
}

export default function MobileMenu({ open, onClose }: IMobileMenuProps) {
  const closeHandler = ensureFn(onClose)
  const { formatMessage } = useIntl()

  return (
    <SwipeableDrawer
      anchor="top"
      onBackdropClick={closeHandler}
      onClose={closeHandler}
      onOpen={() => void 0}
      open={open}
    >
      <List>
        <MobileMenuItem onClick={closeHandler} to={routes.about}>
          {formatMessage(messages.About)}
        </MobileMenuItem>
        <MobileMenuItem onClick={closeHandler} to={routes.howItWorks}>
          {formatMessage(messages.HowitWorks)}
        </MobileMenuItem>
        <MobileMenuItem onClick={closeHandler} to={routes.features}>
          {formatMessage(messages.Features)}
        </MobileMenuItem>
        <MobileMenuItem onClick={closeHandler} to={routes.enterprise}>
          {formatMessage(messages.Enterprise)}
        </MobileMenuItem>
        <MobileMenuItem onClick={closeHandler} to={routes.team}>
          {formatMessage(messages.Team)}
        </MobileMenuItem>
        <MobileMenuItem onClick={closeHandler} to={routes.contact}>
          {formatMessage(messages.Contact)}
        </MobileMenuItem>
      </List>
    </SwipeableDrawer>
  )
}
