import { createElement } from "react"
import { Link } from "gatsby-plugin-intl"
import { GatsbyLinkProps } from "gatsby"
import makeStyles from "@material-ui/core/styles/makeStyles"
import clsx from "clsx"

export interface IHeaderNavLinkProps<TState> extends GatsbyLinkProps<TState> {}

const useCss = makeStyles((theme) => ({
  NavLink: {
    ...theme.typography.body1,
    marginLeft: 15,
    marginRight: 15,
    color: "white",
    textDecoration: "none",
    transition: "color 200ms",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
}))

// TODO Add active state for active page

export default function HeaderNavLink<TState>(
  props: IHeaderNavLinkProps<TState>
) {
  const classes = useCss()
  const { className: otherClassName, ...otherprops } = props

  const className = clsx(classes.NavLink, otherClassName)

  return createElement(Link, {
    className,
    ...otherprops,
  })
}
