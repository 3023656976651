import InstagramIcon from "@components/InstagramIcon"
import Logo from "@components/Logo"
import TwitterIcon from "@components/TwitterIcon"
import YoutubeIcon from "@components/YoutubeIcon"
import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import * as routes from "@routes"
import { CustomTheme } from "@theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import FooterLink from "./FooterLink"
import LanguageSelectorDropdown from "./LanguageSelectorDropdown"
import SocialLink from "./SocialLink"

export interface IFooterProps {}

const useCss = makeStyles((theme: CustomTheme) => ({
  footer: {
    display: "flex",
    padding: "30px 0",
    minHeight: 100,
    backgroundColor: theme.palette.secondary.main,
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  leftInnerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: 25,
    paddingBottom: 30,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
    },
  },
  logoCol: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginRight: "20px",
    },
  },
  logo: {
    width: 156,
    height: 33,
    marginBottom: "12px",
  },
  copyright: {
    color: "white",
    opacity: 0.5,
  },
  link: {
    padding: "10px",
    color: "white",
    opacity: 0.5,
    textDecoration: "none",
    transition: "opacity 200ms",
    "&:hover": {
      opacity: 1,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  socialLinks: {
    display: "flex",
    alignItems: "center",
  },
}))

export default function Footer() {
  const classes = useCss()

  return (
    <footer className={classes.footer}>
      <Container maxWidth="xl" className={classes.innerContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.logoCol}>
            <Logo className={classes.logo}></Logo>
            <Typography variant="body2" className={classes.copyright}>
              Totenpass &copy; {new Date().getFullYear()}
            </Typography>
          </div>
          <div className={classes.leftInnerContainer}>
            <FooterLink to={routes.termsAndConditions} className={classes.link}>
              <FormattedMessage
                defaultMessage="Terms & Conditions"
                id="components.Footer.termsAndConditionsLink"
              />
            </FooterLink>
            <FooterLink to={routes.privacy} className={classes.link}>
              <FormattedMessage
                defaultMessage="Privacy Policy"
                id="components.Footer.privacyLink"
              />
            </FooterLink>
            <FooterLink to={routes.faq} className={classes.link}>
              <FormattedMessage
                defaultMessage="FAQ"
                id="components.Footer.faqLink"
              />
            </FooterLink>
            <FooterLink to={routes.careinstructions} className={classes.link}>
              <FormattedMessage
                defaultMessage="Care Instructions"
                id="components.Footer.careInstructionsLink"
              />
            </FooterLink>
            <FooterLink to={routes.referral} className={classes.link}>
              <FormattedMessage
                defaultMessage="Referral Program"
                id="components.Footer.referralProgram"
              />
            </FooterLink>
            <LanguageSelectorDropdown />
          </div>
        </div>

        <div className={classes.socialLinks}>
          <SocialLink href="https://twitter.com/totenpass">
            <TwitterIcon />
          </SocialLink>
          <SocialLink href="https://instagram.com/totenpass">
            <InstagramIcon />
          </SocialLink>
          <SocialLink href="https://www.youtube.com/channel/UCNGA8bc1h8n9CE4VvNh-vGw">
            <YoutubeIcon />
          </SocialLink>
        </div>
      </Container>
    </footer>
  )
}
