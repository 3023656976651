import { defineMessages } from "gatsby-plugin-intl"

export const messages = defineMessages({
  About: {
    defaultMessage: `About`,
    id: `components.Header.About`,
  },
  HowitWorks: {
    defaultMessage: `How it Works`,
    id: `components.Header.HowitWorks`,
  },
  Features: {
    defaultMessage: `Features`,
    id: `components.Header.Features`,
  },
  Enterprise: {
    defaultMessage: `Enterprise`,
    id: `components.Header.Enterprise`,
  },
  Team: {
    defaultMessage: `Team`,
    id: `components.Header.Team`,
  },
  Contact: {
    defaultMessage: `Contact`,
    id: `components.Header.Contact`,
  },
})
