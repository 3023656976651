import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import Header from "./Header"
import theme from "../theme"
import Footer from "./Footer"

export interface ILayoutProps {
  children: React.ReactNode
}

if (typeof window !== "undefined") {
  // ta-da!
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const useCss = makeStyles({
  main: {
    minHeight: `100vh`,
    overflow: `hidden`,
  },
})

function Layout(props: ILayoutProps) {
  const css = useCss()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={css.main}>{props.children}</main>
      <Footer />
    </MuiThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
