module.exports = [{
      plugin: require('../plugins/gatsby-totenpass-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/usr/home/ec2-user/deploy/totenpass-site/packages/website/src/components/Layout.tsx"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"en","languages":["en"],"path":"/usr/home/ec2-user/deploy/totenpass-site/packages/website/src/lang"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Totenpass","short_name":"mui-starter","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/totenpass-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cd163db964da45a3deb12d24e15bdeeb"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"iph0gkg"},"custom":{"families":["BodoniSvtyTwoOSBold"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WVCLMFD","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
