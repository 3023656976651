import React, { ComponentPropsWithRef } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import clsx from "clsx"

export interface ISocialLinkProps extends Partial<ComponentPropsWithRef<"a">> {}

const useCss = makeStyles((theme) => ({
  SocialLink: {
    alignItems: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
    height: 38,
    marginLeft: 20,
    justifyContent: "center",
    transition: "border-color 200ms",
    width: 38,
    "&:hover": {
      borderColor: "white",
    },
    "&:first-child": {
      marginLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      "&:first-child": {
        marginLeft: 20,
      },
    },
  },
}))

export default function SocialLink(props: ISocialLinkProps) {
  const classes = useCss()
  const { className, ...nativeprops } = props

  return (
    <a
      className={clsx(classes.SocialLink, className)}
      rel="noopener"
      target="_blank"
      {...nativeprops}
    />
  )
}
