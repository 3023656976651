export const about = "/#about"
export const team = "/team"
export const contact = "/#contact"
export const error404 = "/404"
export const features = "/#features"
export const homepage = "/"
export const howItWorks = "/#how-it-works"
export const termsAndConditions = "/terms-and-conditions"
export const privacy = "/privacy-policy"
export const referral = "/referral"
export const faq = "/faq"
export const careinstructions = "/care-instructions"
export const enterprise = "/enterprise"
export const enterprisecontacts = "/enterprise#contacts"
export const leaveReview = `/leave-review`
export const thankYouForReview = `/thank-you-for-review`
