import { createTheme, Theme } from "@material-ui/core/styles"
import * as colorManipulator from "@material-ui/core/styles/colorManipulator"

const primaryColor = "#d8b170"
const secondaryColor = "#01010d"
const primaryTextColor = "#01010d"
const secondaryTextColor = "#777777"
const lightHeadingTextColor = "#eeca8f"
const bgColor = "#fffdf9"
const lightSectionBgColor = "#f2ede4"
const dividerColor = "#dedede"
const errrorColor = "#ce5352"

const unit = 8
const borderRadius = unit
const minWidth650 = "@media (min-width: 650px)"

const customVariables = {
  sectionSpacingLg: 128,
  sectionSpacingMd: 120,
  sectionSpacingMdTop: 96,
  sectionSpacingSm: 64,
  sectionSpacingXs: 32,
  sectionSpacingFluid: "10vw",
  sectionSpacingFluidHalf: "5vw",
  desktopHeaderHeight: 122,
  mobileHeaderHeight: 88,
  lightHeadingTextColor: lightHeadingTextColor,
  primaryTextColor: primaryColor,
  lightSectionBgColor: lightSectionBgColor,
}

export type CustomTheme = Theme & { custom: typeof customVariables }

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 944,
      lg: 1024,
      xl: 1280,
    },
  },
  spacing: unit,
  typography: {
    fontFamily: "sofia-pro, Helvetica, Arial, sans-serif",
  },
  palette: {
    background: {
      default: bgColor,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: primaryTextColor,
      secondary: secondaryTextColor,
    },
    divider: dividerColor,
    error: {
      main: errrorColor,
    },
  },
  props: {
    MuiFormControl: {
      fullWidth: true,
      margin: "normal",
      variant: "filled",
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiSwitch: {
      color: "primary",
    },
    MuiTextField: {
      fullWidth: true,
      margin: "normal",
      variant: "filled",
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    MuiButton: {
      containedPrimary: {
        "&$disabled": {
          backgroundColor: "#CBCBCB",
          color: secondaryTextColor,
        },
      },
      containedSecondary: {
        border: "1px solid",
        borderColor: primaryColor,
        "&:hover": {
          borderColor: "white",
        },
        "&$disabled": {
          backgroundColor: primaryColor,
          color: colorManipulator.alpha(primaryTextColor, 0.54),
        },
      },
      root: {
        padding: "8px 16px",
        borderRadius: 3,
        fontWeight: 700,
        textTransform: "none",
      },
      containedSizeLarge: {
        padding: "14px 30px",
        minWidth: 180,
      },
      text: {
        fontWeight: 400,
      },
      contained: {
        boxShadow: "none",
      },
    },
    MuiButtonGroup: {
      groupedOutlinedPrimary: {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        paddingLeft: unit,
        paddingRight: unit,
      },
    },
    MuiFilledInput: {
      input: {
        padding: "12px 16px 12px",
      },
      multiline: {
        padding: "12px 16px 12px",
      },
      root: {
        backgroundColor: "#fff",
        border: `1px solid`,
        borderColor: dividerColor,
        borderRadius,
        "&:hover": {
          backgroundColor: "#fafafa",
        },
        "&$focused": {
          borderColor: primaryColor,
          backgroundColor: "#fff",
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 10,
        boxShadow: "0 4px 10px rgba(0, 0, 0, .08)",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginBottom: unit,
        marginTop: unit * 4,
      },
    },
    MuiInputBase: {
      input: {
        padding: "12px 16px 12px",
      },
    },
    MuiInputLabel: {
      filled: {
        color: "#000",
        transform: "translate(12px, 16px) scale(1)",
        "&$shrink": {
          transform: "translate(1px, -20px) scale(0.75)",
        },
      },
      root: {
        fontWeight: 700,
      },
    },
    MuiSwitch: {
      switchBase: {
        "&$checked + $track": {
          opacity: 1,
        },
        "& $thumb": {
          transform: "translate(6px, 2px)",
        },
        "&$checked $thumb": {
          transform: "translate(-2px, 2px)",
        },
      },
      thumb: {
        backgroundColor: secondaryColor,
        height: unit * 2,
        width: unit * 2,
      },
      track: {
        borderRadius: 20,
        height: 20,
        transform: "translateY(-3px)",
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "Bodoni-72-OS, serif",
        fontSize: "32px",
        lineHeight: "42px",
        [minWidth650]: {
          fontSize: "40px",
          lineHeight: "54px",
        },
      },
      h2: {
        fontFamily: "Bodoni-72-OS, serif",
        fontSize: "28px",
        lineHeight: "38px",
        [minWidth650]: {
          fontSize: "36px",
          lineHeight: "46px",
        },
      },
      h3: {
        fontFamily: "Bodoni-72-OS, serif",
        fontSize: "20px",
        lineHeight: "30px",
        [minWidth650]: {
          fontSize: "32px",
          lineHeight: "42px",
        },
      },
      h4: {
        fontFamily: "Bodoni-72-OS, serif",
        fontSize: "28px",
        lineHeight: "38px",
      },
      h5: {
        fontFamily: "Bodoni-72-OS, serif",
        fontSize: "24px",
        lineHeight: "34px",
      },
      h6: {
        fontFamily: "Bodoni-72-OS, serif",
        fontSize: "20px",
        lineHeight: "30px",
      },
      gutterBottom: {
        marginBottom: unit * 2,
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
      regular: {
        [minWidth650]: {
          minHeight: unit * 10,
        },
      },
      root: {
        margin: "0 auto",
        maxWidth: unit * 160,
        width: "100%",
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
  },
  shape: {
    borderRadius,
  },
}) as CustomTheme

theme.custom = customVariables

export default theme
